<template>
  <!-- 3.25版本支持 多个店铺卡片需求 -->
  <div 
    v-if="storeCardNum > 1"
    class="search-banner-card_wrapper"
  >
    <div
      ref="searchBannerCardRef"
      class="search-banner-card search-banner-card_multi"
    >
      <component
        :is="cardType"
        v-for="searchStoreCard in searchStoreCardArr"
        :key="searchStoreCard.store_code"
        :search-store-card="searchStoreCard"
        :store-card-num="storeCardNum"
        v-bind="$attrs"
      />
    </div>
    <!-- AR站时，prev在右，next在左 -->
    <!-- prev -->
    <button
      v-if="isShowMoveBtn && showLeftButton"
      class="move-btn btn-prev"
      @click="clickMoveFun('left')"
    >
      <IconsMoreLeftRw />
      <!-- <IconsMoreLeftRw v-if="!gbCssRight" />
      <IconsMoreRightRw v-else /> -->
    </button>
    <!-- next -->
    <button
      v-if="isShowMoveBtn && showRightButton"
      class="move-btn btn-next"
      @click="clickMoveFun('right')"
    >
      <IconsMoreRightRw />
      <!-- <IconsMoreRightRw v-if="!gbCssRight" />
      <IconsMoreLeftRw v-else /> -->
    </button>
  </div>
  <!-- 单个卡片 -->
  <div 
    v-else
    ref="searchBannerCardRef"
    class="search-banner-card"
  >
    <component
      :is="cardType"
      v-if="cardType"
      :search-store-card="searchStoreCardArr?.[0]"
      :search-c-c-c-card="searchCCCCard"
      :store-card-num="1"
      v-bind="$attrs"
    />
  </div>
</template>

<script setup name="SearchCard">
import { computed, onMounted, onUnmounted, ref } from 'vue'
import StoreCard from './storeCard'
import CccCard from './cccCard'
// import OldStoreCard from 'public/src/pages/product_list_v2/components/SearchResultStoreHeader.vue'
// import OldCccCard from 'public/src/pages/product_list_v2/components/SearchCCCCard.vue'

import { IconsMoreLeftRw, IconsMoreRightRw } from '@shein-aidc/icons-laptop'
import { throttle, debounce } from '@shein/common-function'

const searchBannerCardRef = ref(null)
const isOverContent = ref(false)

const gbCssRight = computed(() => gbCommonInfo?.GB_cssRight ?? false)
// 创建两个观察者
let firstCardObserver = null
let lastCardObserver = null
const firstCardRef = ref(null)
const lastCardRef = ref(null)
const showLeftButton = ref(false)
const showRightButton = ref(false)


const props = defineProps({
  searchStoreCardArr: {
    type: Array,
    default: () => [],
  },
  searchCCCCard: {
    type: Object,
    default: () => ({}),
  },
})

const cardType = computed(() => {
  if (props.searchStoreCardArr?.length) {
    return StoreCard
  } else if (props.searchCCCCard) {
    return CccCard
  } else {
    return null
  }
})

// 店铺卡片数量
const storeCardNum = computed(() => {
  return props.searchStoreCardArr?.length
})

// 是否展示滚动按钮
const isShowMoveBtn = computed(() => {
  if(storeCardNum.value <= 1) {
    return false
  }
  // 是否存在滚动条
  return isOverContent.value
})

// 点击滑动
const clickMoveFun = throttle({
  func: (direction) => {
    if(!searchBannerCardRef.value || !direction) {
      return
    }
    const cardScrollLeft = searchBannerCardRef.value?.scrollLeft
    const cardWidth = searchBannerCardRef.value?.offsetWidth
    const cardScrollWidth = searchBannerCardRef.value?.scrollWidth

    // 边界检查 - 考虑RTL模式
    // if(cardScrollLeft === 0 && direction === 'left') {
    //   return
    // }
    // if(cardScrollLeft + cardWidth === cardScrollWidth && direction === 'right') {
    //   return
    // }

    // console.log('触发滑板鞋')
    // 滑动
    const moveWidth = firstCardRef.value?.offsetWidth + 10 || 200
    const scrollDirection = gbCssRight.value ? (direction === 'left' ? moveWidth : -moveWidth) : (direction === 'left' ? -moveWidth : moveWidth)
    // 执行滚动
    searchBannerCardRef.value?.scrollBy({
      // left: direction === 'left' ? -moveWidth : moveWidth,
      left: scrollDirection,
      behavior: 'smooth',
    })
  },
  wait: 100,
})

const handleResize = debounce({
  func: ()=> {
    const clientWidths = searchBannerCardRef.value.clientWidth
    const scrollWidths = searchBannerCardRef.value.scrollWidth
    if(clientWidths === scrollWidths) {
      isOverContent.value = false
    } else {
      isOverContent.value = true
    }
  },
  wait: 100,
})

const detailBtnStatusFun = () => {
  // 获取第一个和最后一个卡片元素
  const cards = searchBannerCardRef.value.children
  if (cards.length > 0) {
    firstCardRef.value = cards[0]
    lastCardRef.value = cards[cards.length - 1]
    
    // 监听第一个卡片可见性，控制左按钮
    firstCardObserver = new IntersectionObserver((entries) => {
      // 当第一个卡片完全可见时，隐藏左按钮
      showLeftButton.value = !entries[0].isIntersecting || entries[0].intersectionRatio < 1
    }, {
      root: searchBannerCardRef.value, 
      threshold: 1.0, // 完全可见才触发
      rootMargin: gbCssRight.value ? '0px 0px 0px 100px' : '0px 100px 0px 0px'
    })
    
    // 监听最后一个卡片可见性，控制右按钮
    lastCardObserver = new IntersectionObserver((entries) => {
      // 当最后一个卡片完全可见时，隐藏右按钮
      showRightButton.value = !entries[0].isIntersecting || entries[0].intersectionRatio < 1
    }, {
      root: searchBannerCardRef.value, 
      threshold: 1.0,
      rootMargin: gbCssRight.value ? '0px 0px 0px 100px' : '0px 100px 0px 0px'
    })
    
    firstCardObserver.observe(firstCardRef.value)
    lastCardObserver.observe(lastCardRef.value)
    
    // 初始状态：右侧按钮显示
    showRightButton.value = true
  }
}
onMounted(()=> {
  // 单个时不需要监听
  if(storeCardNum.value === 1) {
    return
  }
  firstCardRef.value = searchBannerCardRef.value?.children?.[0]
  lastCardRef.value = searchBannerCardRef.value?.lastElementChild

  detailBtnStatusFun()

  handleResize()
  // 针对多个卡片
  window.addEventListener('resize', handleResize)
})
onUnmounted(()=> {
  window.removeEventListener('resize', handleResize)
  // 清理观察者
  firstCardObserver?.disconnect()
  lastCardObserver?.disconnect()
})
</script>

<style lang="less" scoped>
// 多个卡片
.search-banner-card_wrapper{
  position: relative;
  .move-btn {
    position: absolute;
    top: 32%;
    background: #fff;
    border: 1px solid #E5E5E5;
    width: 36px;
    height: 36px;
    padding: 0;
    overflow: hidden;
    border-radius: 50%;
    cursor: pointer;
    z-index: 20;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: center;
    justify-content: center;
    & i {
      width: 18px;
      height: 18px;
    }
  }
  .btn-prev {
    left: 20px;
  }
  .btn-next {
    right: 20px;
  }
}
.search-banner-card_multi {
  display: flex;
  flex-direction: row;
  overflow: scroll;
  gap: 10px;
  // direction: ltr; 
  &::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
}
</style>
