<template>
  <div
    :class="[
      'top-info', 
      'fsp-element',
      { 
        'top-info_img': getBanner, 
        'top-info__view-new': isViewNew
      }
    ]"
    :style="bannerStyle"
  >
    <div
      v-if="isViewNew && catInfo.type == 'search'"
      class="view-new__origin-word-wrap"
      :style="{margin: showSuggestCorrection ? '12px 0 24px 0' : '18px 0'}"
    >
      <p class="view-new__origin-word-main">
        {{ showSuggestCorrection ? language.SHEIN_KEY_PC_23577.replace('"{0}"','') : language.SHEIN_KEY_PC_28059 }}<span>“{{ showSuggestCorrection ? searchKeywords.suggest_words : catName }}”</span><span v-if="showSuggestCorrection">.</span>
      </p>
      <p
        v-if="showSuggestCorrection"
        class="view-new__origin-word-hint"
        @click="(event) => $emit('searchSuggestOriginWord', event)"
        v-html="originSearchWordText"
      ></p>
    </div>

    <!-- 搜索卡片 -->
    <SearchCard
      v-if="isViewNew"
      :search-store-card-arr="searchStoreCard"
      :search-c-c-c-card="searchCCCCard"
      :language="language"
      :shein-club-info="sheinClubInfo"
      :cat-info="catInfo"
      :is-view-new="true"
      :list-abt-result="listAbtResult"
    />
    
    <div
      v-if="isViewNew && getBanner"
      class="top-info__img-wrap"
    >
      <img :src="getBanner" />
    </div>
    <div
      v-else-if="!isViewNew || (isBrandList && getDesc )"
      :class="['top-info__wrapper', { 'top-info__wrapper_img': getBanner }]"
    >
      <section class="top-info__main">
        <section class="top-info__detail">
          <div class="top-info__title">
            <h1
              class="top-info__title-name"
              tabindex="0"
              :aria-label="catName"
              v-text="catName"
            ></h1>
            <span
              v-if="!hideGoodsNum && !isViewNew"
              class="top-info__title-sum"
              role="complementary"
              tabindex="0"
              :aria-label="`${sum}${language.SHEIN_KEY_PC_14931}`"
            >{{ sum }} {{ language.SHEIN_KEY_PC_14931 }}</span>
          </div>
          <div
            v-show="getDesc"
            class="top-info__desc"
            tabindex="0"
            role="contentinfo"
            v-html="getDesc"
          ></div>
        </section>
      </section>
      <!-- 搜索卡片 -->
      <SearchCard
        v-if="!isViewNew"
        :search-store-card-arr="searchStoreCard"
        :search-c-c-c-card="searchCCCCard"
        :language="language"
        :shein-club-info="sheinClubInfo"
        :list-abt-result="listAbtResult"
        :cat-info="catInfo"
      />
      <!-- 标签云 -->
      <CloudTags
        v-if="!isViewNew"
        @select-cloud-tag="$emit('selectCloudTag', $event)"
      />
    </div>
  </div>
</template>

<script>          
import { mapGetters, mapState } from 'vuex'
import CloudTags from './filter/CloudTags'
import SearchCard from 'public/src/pages/product_list_v2/components/SearchCard/index.vue'

// @mock
import { searchResultStoreHeaderData as storeData } from '../mock-data'

export default {
  name: 'TopInfo',
  emits: ['selectCloudTag', 'searchSuggestOriginWord'],
  components: {
    CloudTags,
    SearchCard,
  },
  props: {
    isViewNew: {
      type: Boolean,
      default: false,
    },
    isSuggestCorrection: {
      type: Boolean,
      default: false
    },
    originSearchWordText: {
      type: String,
      default: ''
    },
    listAbtResult: {
      type: Object,
      default: () => ({})
    }
  },
  inject: ['transformImg', 'hideGoodsNum'],
  data() {
    return {
      storeData
    }
  },
  computed: {
    ...mapGetters([
      'currentCat',
      'catInfo',
      'searchKeywords',
      'cccListOperation',
      'sum',
      'searchCCCCard',
      'searchStoreCard',
      'cccxConfig',
      'sheinClubInfo'
    ]),
    ...mapState(['language', 'SiteUID', 'IS_RW']),
    catName() {
      if (this.catInfo.type == 'daily_new') {
        return this.language.SHEIN_KEY_PC_15501
      } else if (this.catInfo.type == 'search') {
        let title =
          this.searchKeywords.suggest_words ||
          this.searchKeywords.origin_words ||
          ''
        if (title && title.trim()) {
          title =
            title
              .trim()
              .replace(/&gt;/g, '>')
              .replace(/&lt;/g, '<')
              .split(/\s+/)
              .map((v) => v.replace(v[0], v[0].toUpperCase()))
              .join(' ') || ''
        }
        if (title && this.catInfo.search_cat) {
          if (this.SiteUID === 'hk' || this.SiteUID === 'tw') {
            title =
              this.language?.SHEIN_KEY_PC_16957?.replace('{1}', title).replace(
                '{0}',
                this.catInfo.search_cat
              ) || ''
          } else {
            title =
              this.language?.SHEIN_KEY_PC_16956?.replace('{0}', title).replace(
                '{1}',
                this.catInfo.search_cat
              ) || ''
          }
        }
        return title
      } else if (this.catInfo.type == 'selection') {
        return this.romweXName || this.currentCat?.select_name_multi || ''
      } else if (this.catInfo.type == 'selling') {
        return this.currentCat.valName || ''
      } else {
        return this.currentCat.cat_name || ''
      }
    },
    getBanner () {
      // 新样式下, 真实 || 选品 || 品牌列表不展示banner
      if (
        this.isViewNew
        && (this.catInfo.type === 'entity'
          || this.catInfo.type === 'selection'
          || this.catInfo.sub_type === 'Brands'
        )) return ''

      // 优先取来自cccx配置的图片
      const imageSrc = this.cccxConfig?.top_list_zone?.content[0]?.props?.items[0]?.image?.src
      if (imageSrc) return imageSrc

      // 再取老ccc配置的图片
      let bannerUrl = ''
      const content = this.cccListOperation?.pc_list_topbanner?.content

      if (content && Array.isArray(content)) {
        content.forEach((_) => {
          const items = _?.content?.props?.items
          if (Array.isArray(items)) {
            items.forEach(
              (__) => (bannerUrl = this.transformImg({ img: __.imgSrc }))
            )
          }
        })
      }

      return bannerUrl
    },
    bannerStyle () { 
      if (!this.getBanner) return {}
      const styles = {}

      if (this.isViewNew) {
        styles.marginBottom = '20px'
      } else {
        styles.background = `#f7f8fa url('${this.getBanner}') no-repeat right top/cover`
      }

      return styles
    },
    getDesc() {
      if (this.catInfo.type === 'daily_new') {
        return this.language.SHEIN_KEY_PC_15502
      }

      if (this.catInfo.type === 'selection') {
        if (this.romweXName) return this.language.SHEIN_KEY_PC_20333 // ROMWE X 艺术家

        const typeKey = +[this.currentCat?.brande_detail?.detail_type]
        if (typeKey === 1 || typeKey === 2)
          return this.currentCat?.brande_detail?.brand_introduction
      }

      return ''
    },
    romweXName() {
      // ROMWE X 艺术家
      return this.IS_RW &&
        this.currentCat?.brande_detail?.detail_type == '2' &&
        this.currentCat?.brand_code == '28'
        ? 'ROMWE X'
        : null
    },
    // 是否品牌列表
    isBrandList () {
      return this.catInfo.sub_type === 'Brands'
    },
    showSuggestCorrection() {
      return this.isSuggestCorrection && !!this.searchKeywords.suggest_words
    }
  },
}
</script>

<style lang="less">
.product-list-v2 {
  .top-info {
    font-family: 'Muli', 'Arial', 'Helvetica', sans-serif !important; /* stylelint-disable-line declaration-no-important */
    &_img {
      padding: 12px;
    }
    &__img-wrap {
      width: 100%;
      
      img {
        display: block;
        width: 100%;
      }
    }
    &__wrapper {
      padding: 24px;
      background-color: #f6f6f6;
      &_img {
        background-color: #fff;
        width: 50%;
        @media (max-width: 1200px) {
          width: 75%;
        }
      }
    }
    &__main {
      display: flex;
      margin-bottom: 24px;
    }
    &__logo {
      width: 96px;
      height: 96px;
      margin-right: 16px;
      border: 1px solid #e5e5e5;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      > img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    &__detail {
      flex: 1;
      align-self: center;
    }
    &__title {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      > :not(:last-child) {
        margin-right: 8px;
      }
    }
    &__title-name {
      font-size: 20px;
      text-transform: none;
    }
    &__title-icon {
      padding: 0 6px;
      line-height: 20px;
      color: #198055;
      background-color: #f5fcfb;
      display: inline-block;
      > i {
        float: left;
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
    }
    &__title-sum {
      font-size: 14px;
      color: #767676;
    }
    &__desc {
      color: #767676;
      margin-top: 8px;
      line-height: 14px;
    }
    &__view-new {
      .top-info__title {
        font-size: 12px;
        margin: 18px 0;
        color: #767676;

        span {
          margin-left: 5px;
          color: #222;
          font-size: 15px;
          font-weight: 700;
        }
      }

      .top-info__wrapper {
        margin-bottom: 18px;
        padding: 12px 16px;

        /* stylelint-disable-next-line selector-max-specificity */
        .top-info__title {
          margin: 0 0 8px 0;
        }
      }

      .top-info__main {
        margin-bottom: 0;
      }

      .top-info__desc {
        line-height: normal;
      }
      .view-new__origin-word-wrap {
        display: flex;
        margin: 18px 0;
        font-size: 12px;
        line-height: 17px;
        color: #767676;
        align-items: baseline;
      }
      .view-new__origin-word-main {
          span {
            color: var(---sui_color_gray_dark1, #222);
            font-size: 15px;
            font-weight: 700;
            font-style: italic;
          }
        }
        .view-new__origin-word-hint {
          margin-left: 4px;
          /* stylelint-disable-next-line selector-max-specificity */
          .link {
            color: #2D68A8;
            cursor: pointer;
            font-style: italic;
            font-weight: 700;
            text-decoration: underline;
          }
        }
    }
  }
}
</style>
