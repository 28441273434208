import schttp from 'public/src/services/schttp'

const bffRquset = async ({ products, sheinClubInfo }) => {
  const res = await schttp({
    useBffApi: true,
    method: 'POST',
    url: '/recommend/buybox/buybox_entrance',
    data: { 
      goodsInfos: products,
      isPaidMember: sheinClubInfo?.isPaid ? 1 : 0,
    }
  })

  const buyBoxEntranceList = res?.info?.buyBoxEntranceList || []
  if (buyBoxEntranceList.length > 0) {
    const product = buyBoxEntranceList.map((item) => {
      return {
        goods_id: Number(item.goodsId),
        buyBoxPrice: {
          price: item.buyBox?.lowestPrice
        }
      }
    }) 
    return product || []
  }
  return []
}

// buybox入口请求
export default {
  methods: {
    async getBuyBoxPrices (goods, sheinClubInfo) {
      if (typeof window === 'undefined') return

      const products = goods
        .filter(item => item.buyBoxCount?.count)
        .map(item => {
          return {
            // java中间层用
            goodsId: item.goods_id,
            goodsSn: item.goods_sn,
            mallCode: item.mall_code
          }
        })
    
      if (!products.length) return

      await this.$nextTick()
      window?.requestIdleCallback(async() => {

        const list = await bffRquset({
          products, 
          sheinClubInfo
        })

        list?.forEach(item => {
          const curGoods = this?.goods?.find(obj => Number(obj.goods_id) === Number(item.goods_id))
          if (!curGoods) return
          curGoods.buyBoxPrice = item.buyBoxPrice
        })
      }, {
        timeout: 1500,
      })
      
    },
  },
}
